import React, {useState} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';

import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';

import ReactHLS from 'react-hls';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'

Modal.setAppElement(`#___gatsby`);

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)",
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    maxWidth: "960px",
    margin: "32px auto",
    padding: 0,
    border: 0,
  },
};

let workingDaysBetweenDates = (d0, d1) => {
  /* Two working days and an sunday (not working day) */
  var holidays = ['2020-01-01'];
  var startDate = parseDate(d0);
  var endDate = parseDate(d1);  

// Validate input
  if (endDate <= startDate) {
    return 0;
  }

// Calculate days between dates
  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1);  // Start just after midnight
  endDate.setHours(23, 59, 59, 999);  // End just before midnight
  var diff = endDate - startDate;  // Milliseconds between datetime objects    
  var days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  var weeks = Math.floor(days / 7);
  days -= weeks * 2;

  // Handle special cases
  var startDay = startDate.getDay();
  var endDay = endDate.getDay();
    
  // Remove weekend not previously removed.   
  if (startDay - endDay > 1) {
    days -= 2;
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay === 0 && endDay !== 6) {
    days--;  
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay === 6 && startDay !== 0) {
    days--;
  }
  /* Here is the code */
  holidays.forEach(day => {
    if ((day >= d0) && (day <= d1)) {
      /* If it is not saturday (6) or sunday (0), substract it */
      if ((parseDate(day).getDay() % 6) !== 0) {
        days--;
      }
    }
  });
  return days;
}
function parseDate(input) {
  // Transform date from text to date
  var parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1]-1, parts[2]); // months are 0-based
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function getTooltipDataAttrs(value) {
  // Temporary hack around null value.date issue
  if (!value || !value.date) {
    return null;
  }
  // Configuration for react-tooltip
  return {
    'data-tip': `On ${value.date}, Scott did ${value.count} exercises with ${value.tutor}`,
  };
}

function avgClassCount(count) {
  if (count > 1000) {
    let k = Math.round(count / 1000)
    return `> ${k}000 classes`
  }
  if (count > 500) {
    return `> 500 classes`
  }
  if (count > 250) {
    return `> 250 classes`
  }
  if (count > 100) {
    return `> 100 classes`
  }
  return 'new tutor'
}

function getDayOfWeek(date) {
  const dayOfWeek = date.getDay();    
  return isNaN(dayOfWeek) ? null : 
    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
}

const IndexPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentClass, setCurrentClass] = useState(false);
  function closeModal(){
    setModalOpen(false);
  }
  function clickClass(data){
    setCurrentClass(data);
    setModalOpen(true);
  }

  const queryData = useStaticQuery(graphql`
    query TutorsQuery {
      allClassesJson(sort: {fields: date, order: DESC}) {
        nodes {
          date
          count
          tutor
          videoUrl
        }
      }
      allTutorsJson {
        nodes {
          avatar
          langs
          login
          name
          sessions
          loc
        }
      }
      allPermissionsJson {
        nodes {
          permission
          tutor
        }
      }
    }
  `)

  // class info
  let classes = queryData.allClassesJson.nodes;
  const tutors = classes.map(x => x.tutor).filter(onlyUnique);

  // tutor data map
  let tutorJson = queryData.allTutorsJson.nodes;
  let tutorMap = new Map()
  tutorJson.forEach(e => tutorMap[e.login] = e);

  // tutor data map
  let permJson = queryData.allPermissionsJson.nodes;
  permJson.forEach(e => tutorMap[e.tutor].permission = e.permission);
  console.log(tutorMap)

  let todayDate = new Date()
  let goal = 200;
  var dateString = todayDate.getFullYear()  + "-" + (todayDate.getMonth()+1) + "-" + todayDate.getDate()
  let wdays = workingDaysBetweenDates('2021-01-02', dateString);

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(2021, 0, 1);
  const yearDays = Math.round(Math.abs((firstDate - todayDate) / oneDay));
  let year_pct = Math.round((yearDays / 365) * 100.0);

  let study_days = classes.length
  let study_pct = Math.round((study_days / wdays) * 100.0);
  let goal_pct = Math.round((study_days / goal) * 100.0);
  let on_track = false;
  if(study_pct >= 75) {
    on_track = true;
  }

  let classDate = null;
  if(currentClass) {
    classDate = parseDate(currentClass.date)
  }

  return (
    <Layout>
      <SEO title="Scott's Language Challenge"/>
      <div className="flex flex-col justify-center justify-items-center p-4">
        <div className="text-xl">My goal in 2021 is to take <strong>{goal}</strong> Live Lessons with Chatterbug.</div>
        <div className="text-sm text-gray-500 italic">There are 261 working days in 2021, so this is about 75% of the working days this year.</div>
      </div>

      {!!on_track && 
      <div className="flex flex-col justify-center justify-items-center p-4 bg-green-100 rounded">
        <div className="block text-green-600 text-xl font-bold">On Track.</div>
        <div className="block">
          I have done <strong>{study_days}</strong> Chatterbug Live Lessons over <strong>{wdays}</strong> weekdays in 2021, 
          or <strong>{study_pct}%</strong> of the year's working days.
        </div>
        <div className="text-base">
          This is <strong>{study_days * 45}</strong> minutes of lessons, or about <strong>{Math.round(study_days * .75)} hours</strong>.
        </div>
        <div className="mt-2">
         I am <strong>{goal_pct}%</strong> to my goal ({study_days}/{goal})
         <span className="ml-2 text-sm text-green-500 italic">and we are {year_pct}% through the year ({yearDays}/365)</span>
        </div>
        <div className="relative pt-3">
          <div className="overflow-hidden h-2 mb-2 text-xs flex rounded bg-green-200">
            <div style={{width: `${goal_pct}%`}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
          </div>
        </div>
      </div>
      }
      {!on_track && 
      <div className="flex flex-col justify-center justify-items-center p-4 bg-red-100 rounded">
        <div className="block text-red-600 font-bold">Falling Behind!</div>
        <div className="block">
          I have done a Chatterbug Live Lesson for <strong>{study_days}/{wdays}</strong> weekdays in 2021, 
          or only <span className="text-red-600 font-bold">{study_pct}%</span> of the year's working days.
        </div>
        <div>I am <strong>{goal_pct}%</strong> to my goal ({study_days}/{goal})</div>
        <div className="relative pt-1">
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
            <div style={{width: `${goal_pct}%`}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500"></div>
          </div>
        </div>
      </div>
      }
      <div className="py-4 pb-0" style={{ maxWidth: `100%`, marginBottom: `1.45rem` }}>
        <CalendarHeatmap
        startDate={new Date('2021-01-01')}
        endDate={new Date('2021-12-31')}
        showWeekdayLabels={true}
        showMonthLabels={true}
        tooltipDataAttrs={getTooltipDataAttrs}
        classForValue={(value) => {
          if (!value) {
            return 'color-empty';
          }
          if (value.count >= 5) {
            return `color-gitlab-4`;
          }
          return `color-gitlab-${value.count}`;
        }}
        values={classes}
      />
      </div>
      <div className="text-xl font-bold my-2">
        My Tutors
        <span className="text-sm ml-2 text-gray-400">{tutors.length}</span>
      </div>
      <div className="grid grid-cols-5 gap-2 mb-10">
      {tutors.map(function(data, index){
        if (tutorMap[data]){
          return (
          <div key={'tutor-' + index} className="flex bg-yellow-100 p-2 pb-1 rounded">
            <div className='w-3/12'>
              <img style={{width:'40px', height:'40px'}} className="inline rounded-full" src={tutorMap[data].avatar}/>
            </div>
            <div className="w-9/12 px-2">
              <div className="truncate"><a className="text-yellow-600" href={`https://app.chatterbug.com/${data}`}>{data}</a></div>
              <div className="text-xs text-yellow-500">{avgClassCount(tutorMap[data].sessions)}</div>
            </div>
          </div>
          );
      }
      })}
      </div>

      <div className="text-xl font-bold my-2">My Classes</div>
      <div className="grid grid-cols-4 gap-2">
      {classes.map(function(data, index){
        let hasVideo = (tutorMap[data.tutor] && tutorMap[data.tutor].permission === true) && data.videoUrl;
        return (
        <div key={'class-' + index} className={(hasVideo ? 'bg-purple-200' : 'bg-blue-100') + ' px-2 pt-3 pb-1 rounded'}>
          <div>
            {tutorMap[data.tutor] &&
              <img className="float-right rounded-full" style={{width: '30px', height: '30px'}} src={tutorMap[data.tutor].avatar}/>
            }
            <a className="text-blue-600 underline" href={`https://app.chatterbug.com/${data.tutor}`}>{data.tutor}</a>
            <div className="text-sm text-gray-500">
            <small>{data.date}</small>
            </div>
          </div>
          <div class="relative">
          {hasVideo &&
            <div className="absolute top-2 right-3">
              <FontAwesomeIcon className="text-purple-500" icon={faVideo} size="1x" />
            </div>
          }
          <img className="rounded" 
               width="200" 
               src={`classes/tn/${data.date}.png`} 
               title={data.date} 
               alt={data.date}
               onClick={e => clickClass(data)}
               />
          </div>
        </div>
        );
      })}
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <div className="p-10">
          {!!currentClass &&
          <div>
            <h2>Class on {currentClass.date}</h2>
            <div className="mb-5">On {getDayOfWeek(classDate)}, {classDate.getMonth()+1}/{classDate.getDate()}, I took a Chatterbug Live Lesson with 
              <a className="text-blue-600 underline ml-1" href={`https://app.chatterbug.com/${currentClass.tutor}`}>{currentClass.tutor}</a>
            </div>
            {!!currentClass.videoUrl &&
            <ReactHLS 
              url={currentClass.videoUrl} 
              autoplay={true}
              />
            }
            {!currentClass.videoUrl &&
            <img className="rounded" 
                width="800" 
                src={`classes/${currentClass.date}.png`} 
                title={currentClass.date} 
                alt={currentClass.date}
                />
            }
          </div>
          }
        </div>
      </Modal>
      <ReactTooltip />
    </Layout>);
}

export default IndexPage
